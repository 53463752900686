import { Link, useLocation } from "react-router-dom";

const Topbar = ({ siteInfo }) => {
  const { pathname } = useLocation();

  const links = [
    {
      name: "About Us",
      url: "/about",
      subLinks: [
        // {
        //   name: "Home Default",
        //   url: "/",
        // },
        {
          name: "About Us",
          url: "/about",
        },
        {
          name: "How We Work",
          url: "/how-we-work",
        },
        {
          name: "Our Team",
          url: "/our-team",
        },
        {
          name: "Our Value",
          url: "/our-value",
        },
        {
          name: "Mission Vision",
          url: "/mission-vision",
        },
      ],
    },
    {
      name: "Service",
      url: "/service",
      subLinks: [
        {
          name: "Service",
          url: "/service",
        },
        {
          name: "Pricing",
          url: "/pricing",
        },
        {
          name: "Contact",
          url: "/contact",
        },
        {
          name: "FAQ",
          url: "/faq",
        },
        {
          name: "Coming Soon",
          url: "/coming-soon",
        },
      ],
    },
    {
      name: "Portfolio",
      url: "/portfolio",
      subLinks: [
        {
          name: "Portfolio",
          url: "/portfolio",
        },
        {
          name: "Testimonial",
          url: "/testimonial",
        },
        {
          name: "Careers",
          url: "/careers",
        },
        {
          name: "Privacy & Policy",
          url: "/privacy",
        },
        {
          name: "Terms and Conditions",
          url: "/terms-conditions",
        },
      ],
    },
    // {
    //   name: "Contact",
    //   url: "/contact",
    // },
    // {
    //   name: "Blog",
    //   url: "/#",
    // },
    // {
    //   name: "Login",
    //   url: "/#",
    // },
  ];

  return (
    <header className="header default">
      <div className="topbar">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="d-block d-md-flex align-items-center text-center">
                <div className="me-4 d-inline-block py-1">
                  <a href={"mailto:" + siteInfo?.email}>
                    <i className="far fa-envelope me-2 fa-flip-horizontal text-primary" />
                    {siteInfo?.email}
                  </a>
                </div>
                <div className="me-auto d-inline-block py-1">
                  <a href={"mailto:" + siteInfo?.email}>
                    <i className="fas fa-map-marker-alt text-primary me-2" />

                    {siteInfo?.address}
                    {/* Plot 19, Wole Soyinka Avenue, 2nd Avenue, Gwarimpa Estate, */}
                  </a>
                  <a
                    style={{ marginLeft: 20 }}
                    href={"mailto:" + siteInfo?.email}
                  >
                    <i className="fas fa-map-marker-alt text-primary me-2" />
                    55, Gildner Street, Kitchener, Ontario, Canada
                  </a>
                </div>
                <div className="d-inline-block py-1">
                  <ul className="list-unstyled">
                    <li>
                      <Link to="/careers">Careers</Link>
                    </li>
                    <li>
                      <Link to="/blogs">News &amp; Media</Link>
                    </li>
                    <li>
                      <Link to="/faq">FAQ</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <nav className="navbar bg-white navbar-static-top navbar-expand-lg">
        <div className="container-fluid">
          <button
            type="button"
            className="navbar-toggler"
            data-bs-toggle="collapse"
            data-bs-target=".navbar-collapse"
          >
            <i className="fas fa-align-left" />
          </button>
          <Link className="navbar-brand" to="/">
            <img className="img-fluid" src={siteInfo?.logo} alt="logo" />
          </Link>
          <div className="navbar-collapse collapse">
            <ul className="nav navbar-nav">
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/"
                  // id="navbarDropdown"
                  // role="button"
                  // data-bs-toggle="dropdown"
                  // aria-haspopup="true"
                  // aria-expanded="false"
                >
                  Home
                </Link>
              </li>
              {links.map((link, i) => {
                return (
                  <li
                    key={i}
                    className={
                      pathname === link.url && link.subLinks
                        ? "nav-item dropdown active"
                        : !link.subLinks && pathname === link.url
                        ? "nav-item active"
                        : link.subLinks
                        ? "nav-item dropdown"
                        : "nav-item"
                    }
                  >
                    <Link
                      className="nav-link"
                      to={link.url}
                      id="navbarDropdown"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {link.name}
                    </Link>
                    {link.subLinks && (
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        {link.subLinks?.map((subLink, i) => {
                          return (
                            <li
                              key={i}
                              className={
                                subLink.url === pathname ? "active" : ""
                              }
                            >
                              <Link className="dropdown-item" to={subLink.url}>
                                {subLink.name}
                                <i className="fas fa-arrow-right" />
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </li>
                );
              })}
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/blogs"
                  // id="navbarDropdown"
                  // role="button"
                  // data-bs-toggle="dropdown"
                  // aria-haspopup="true"
                  // aria-expanded="false"
                >
                  Blogs
                </Link>
              </li>
              {/* <li className="dropdown nav-item mega-menu">
                <a href="#" className="nav-link" data-bs-toggle="dropdown">
                  Prices
                </a>
                <ul className="dropdown-menu megamenu">
                  <li>
                    <div className="row">
                      <div className="col-sm-6 col-lg-3">
                        <h6 className="mb-3 nav-title">Menu Title </h6>
                        <ul className="list-unstyled mt-lg-3">
                          <li>
                            <a href="#">Menu item </a>
                          </li>
                          <li>
                            <a href="#">Menu item </a>
                          </li>
                          <li>
                            <a href="#">Menu item </a>
                          </li>
                          <li>
                            <a href="#">Menu item </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                </ul>
              </li> */}
            </ul>
          </div>
          <div className="d-none d-sm-flex ms-auto me-5 me-lg-0 pe-4 pe-lg-0">
            <ul className="nav ms-1 ms-lg-2 align-self-center">
              <li className="contact-number nav-item">
                <a
                  className="fw-bold"
                  href={`https://wa.me/+234${siteInfo?.phone3}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-whatsapp pe-2" />
                  {siteInfo?.phone3}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Topbar;
